@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Work Sans', sans-serif;
}

body {
    scroll-behavior: smooth;
}

/* ----- input ----- */
.input-form {
    @apply w-[100%] px-4 py-2 rounded-md border text-sm placeholder:text-sm focus:outline-none;
}

.textarea-form {
    @apply w-[100%] h-[150px] px-4 py-2 rounded-md border text-sm placeholder:text-sm focus:outline-none;
}

/* ----- button ----- */
.default-btn {
    @apply w-[100%] px-4 py-2 mb-6 text-sm text-white tracking-wider bg-primaryDark border 
    rounded-[0.375rem] border-primaryDark hover:text-primaryDark hover:bg-white transition duration-200;
}

.swiper-btn {
    @apply px-4 py-2 lg:py-3 lg:w-[160px] text-white text-sm uppercase bg-primaryDark border rounded-[0.375rem] 
    border-primaryDark hover:text-primaryDark hover:bg-white transition duration-200
}

.secondary-btn {
    @apply px-4 py-2 mb-4 text-sm text-white bg-primaryDark border rounded-[0.375rem] border-primaryDark hover:text-primaryDark 
    hover:bg-white transition duration-200;
}

.pagination-btn {
    @apply w-[30px] px-2 py-1 rounded-md border border-primaryDark;
}

.active-pagination-btn {
    @apply w-[30px] px-2 py-1 font-semibold rounded-md text-white bg-primaryDark;
}

/* ----- footer ----- */
.footer-shop-info {
    @apply flex items-center gap-x-2 mb-3 text-footerText hover:text-white transition-all duration-150 cursor-pointer;
}

.footer-text {
    @apply text-footerText mb-3 hover:text-white transition-all duration-150 cursor-pointer;
}

/* ----- table ----- */
.list-table {
    @apply w-[1200px] overflow-x-scroll mb-10 lg:w-[100%] border border-collapse border-spacing-2.5 table-auto;
}

/* ----- scrollbar ----- */
.scrollbar {
    scrollbar-width: thin;
    overflow-x: scroll;
}

.scrollbar::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar {
    width: 7px;
}
  
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
::-webkit-scrollbar-thumb {
    background: #415a77;
    border-radius: 50px;
}
  
::-webkit-scrollbar-thumb:hover {
    background: #555;
}